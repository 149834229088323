import CloseButton from 'react-bootstrap/CloseButton';

const EpisodePage = () => {

    return (

        <>
    <a href="/"><CloseButton aria-label="Hide" /></a>

        episode component 

        photo

        image

        audio

        info

        links

        
        
        </>
    )

}

export default EpisodePage;